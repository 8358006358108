import { Injectable } from '@angular/core';
import { SportType } from 'src/app/core/services/moveup-api';
import { SelectOption } from 'src/app/shared/components/form/select/select.component';

@Injectable()
export class SportsHelper {
    static getSportOptions(): SelectOption<SportType>[] {
        return [
            { value: 'americanFootball', label: 'American Football' },
            { value: 'baseball', label: 'Baseball' },
            { value: 'basketball', label: 'Basketball' },
            { value: 'beachVolleyball', label: 'Beach Volleyball' },
            { value: 'boxing', label: 'Boxing' },
            { value: 'cricket', label: 'Cricket' },
            { value: 'handball', label: 'Handball' },
            { value: 'hockey', label: 'Hockey' },
            { value: 'judo', label: 'Judo' },
            { value: 'soccer', label: 'Soccer' },
            { value: 'tableTennis', label: 'Table Tennis' },
            { value: 'taekwondo', label: 'Taekwondo' },
            { value: 'tennis', label: 'Tennis' },
            { value: 'volleyball', label: 'Volleyball' },
        ];
    }

    static sportHasDraw(sport: SportType): boolean {
        const sportsWithDraw: SportType[] = ['soccer', 'boxing', 'handball'];

        return sportsWithDraw.includes(sport);
    }

    static getSportLabel(sport: SportType): string {
        const sportOptions = SportsHelper.getSportOptions();
        const sportOption = sportOptions.find((option) => option.value === sport);

        return sportOption ? sportOption.label : '';
    }
}
